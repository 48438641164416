<template>
  <v-row align="center" :class="icon ? 'mt-3' : 'mt-1'">
    <v-col cols="auto" :class="!icon ? 'px-1' : ''">
      <v-img :src="icon" />
    </v-col>
    <v-col class="px-0">
      <h2>{{ title }}</h2>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    color: String,
  },
};
</script>
