<template>
  <div v-if="verifyAuthority">
    <TitleRow title="Configuração de Arquivos" icon="icons/fileConfigs.png" />
    <v-row class="mt-10">
      <CustomMenu
        v-for="(menu, index) in fileConfigurations"
        :key="index"
        :icon="menu.icon"
        :title="menu.title"
        :color="menu.color"
        :authority="menu.authority"
        :paddingCustom="menu.paddingCustom"
        @click="redirectRouter(menu.routerName, { isRHProtegido: isRHProtegido})"
      />
    </v-row>
  </div>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import TitleRow from '@/components/Home/AllMenus/TitleRow.vue';
import CustomMenu from '@/components/Home/AllMenus/CustomMenu.vue';

export default ({
  components: { TitleRow, CustomMenu },
  data: () => ({
    hasNoAuthorities: false,
    isRHProtegido: false,
  }),

  mixins: [
    VerifyRoutesMixin,
  ],

  created() {
    this.userUtils = new UserUtils();
    this.emitVerifyAuthority();
  },

  computed: {
    fileConfigurations() {
      return [
        {
          icon: 'icons/layoutModel.png',
          title: 'Modelos de Layout',
          color: '#08d7d7',
          routerName: 'LayoutModel',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_modelo_layout' }),
        },
        {
          icon: 'icons/layoutImports.png',
          title: 'Layout Importação',
          color: '#08d7d7',
          routerName: 'LayoutImports',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_layout_importacao' }),
        },
        {
          icon: 'icons/collectors.png',
          title: 'Coletores',
          color: '#08d7d7',
          routerName: 'Collectors',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_coletores' }),
        },
        {
          icon: 'icons/comissionExtracts.png',
          title: 'Extratos de Comissão',
          color: '#08d7d7',
          routerName: 'ComissionExtracts',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_extratos_comissao' }),
        },
        {
          icon: 'icons/accompanimentSinisterAndInvoice.png',
          title: 'Cargas Sinistros e Faturas',
          color: '#08d7d7',
          routerName: 'AccompanimentSinisterAndInvoice',
          paddingCustom: '15px',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cargas_sinistros_faturas' }),
        },
        {
          icon: 'icons/batchConfiguration.png',
          title: 'Config. Geração de Lote',
          color: '#08d7d7',
          routerName: 'BatchConfiguration',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_configuracao_geracao_lote' }),
        },
        {
          icon: 'icons/reportAutomation.png',
          title: 'Automação de Relatórios Cliente',
          color: '#08d7d7',
          paddingCustom: '15px',
          routerName: 'CustomerReportAutomation',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_automacao_relatorios_cliente' }),
        },
        {
          icon: 'icons/operationalRoutines.png',
          title: 'Conferência de Fatura',
          color: '#08d7d7',
          routerName: 'OperationalRoutines',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_faturas_conferencia' }),
        },
        {
          icon: 'icons/dataLoad.png',
          title: 'Carga de Dados',
          color: '#08d7d7',
          paddingCustom: '15px',
          routerName: 'DataLoad',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_import_inv_ext_files_data' }),
        },
      ];
    },
    verifyAuthority() {
      return this.fileConfigurations.some((menu) => menu.authority === true);
    },
  },

  methods: {
    emitVerifyAuthority() {
      this.$emit('verifyAuthorityFileConfigurations', this.verifyAuthority);
    },
  },
});
</script>
