<template>
  <div v-if="verifyAuthority">
    <TitleRow title="Movimentações" icon="icons/movementsMenu.png" />
      <v-row class="mt-10">
      <CustomMenu
        v-for="(menu, index) in movements"
        :key="index"
        :icon="menu.icon"
        :title="menu.title"
        :color="menu.color"
        :authority="menu.authority"
        :paddingCustom="menu.paddingCustom"
        :path="menu.path"
        @click="redirectRouter(menu.routerName, menu.query)"
      />
    </v-row>
  </div>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import TitleRow from '@/components/Home/AllMenus/TitleRow.vue';
import CustomMenu from '@/components/Home/AllMenus/CustomMenu.vue';

export default ({
  components: { TitleRow, CustomMenu },
  data: () => ({
    hasNoAuthorities: false,
    isRHProtegido: false,
  }),

  mixins: [
    VerifyRoutesMixin,
  ],

  created() {
    this.userUtils = new UserUtils();
    this.emitVerifyAuthority();
  },

  computed: {
    movements() {
      return [
        {
          icon: 'icons/movements.png',
          title: 'Movimentações Cadastrais',
          color: '#6428bd',
          routerName: 'Movement',
          query: { isRHProtegido: this.isRHProtegido },
          paddingCustom: '14px',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais' }),
        },
        {
          icon: 'icons/searchInsured.png',
          title: 'Pesquisa de Beneficiário',
          color: '#6428bd',
          routerName: 'SearchInsured',
          query: { isRHProtegido: this.isRHProtegido },
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_pesquisa_beneficiario' }),
        },
      ];
    },
    verifyAuthority() {
      return this.movements.some((menu) => menu.authority);
    },
  },

  methods: {
    emitVerifyAuthority() {
      this.$emit('verifyAuthorityMovements', this.verifyAuthority);
    },
  },
});
</script>
