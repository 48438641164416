<template>
  <v-col v-if="authority" cols="3" @click="handleClick" style="cursor: pointer;">
    <v-row align="center">
      <v-col cols="auto">
        <div class="square" :style="`background-color: ${color}; padding: ${paddingCustom}`">
          <v-img :src="icon" />
        </div>
      </v-col>
      <v-col class="px-0 pr-5">
        <h3 class="font-weight-regular">{{ title }}</h3>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'CustomMenu',
  props: {
    icon: String,
    title: String,
    color: String,
    authority: Boolean,
    paddingCustom: String,
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style>
.square {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px
}

.icon {
  display: block;
  margin: auto;
}
</style>
